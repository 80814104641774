<template>
    <v-app
        :style="{
            background:
                isWidget && theme === 'light'
                    ? '#fff'
                    : $vuetify.theme.themes[theme].background,
        }"
    >
        <CollectUserDetails />
        <router-view></router-view>
        <v-spacer></v-spacer>
        <Footer
            v-if="
                !isBrandedApp &&
                !isSuccessOrFailedRoute &&
                !isWebsiteForm &&
                showVibefamFooter
            "
        />
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'
import CollectUserDetails from '@/components/shared/CollectUserDetails.vue'
import Footer from '@/components/footer/Footer'
import { WIDGET_ROUTE } from '@/constants'

export default {
    name: 'App',
    components: {
        Footer,
        CollectUserDetails,
    },
    mounted() {
        this.authAction()
    },
    watch: {
        isUserAuth: {
            handler() {
                if (this.user) {
                    this.$gtag.config({ user_id: this.user.id })
                } else {
                    this.$gtag.config({ user_id: null })
                }
            },
            immediate: true,
        },
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mdAndDown
        },
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isLoading: `${AUTHENTICATION_MODULE_NAME}/isLoading`,
            isBrandedApp: `${AUTHENTICATION_MODULE_NAME}/isBrandedApp`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
        isSuccessOrFailedRoute() {
            return (
                this.$route.path.includes('success') ||
                this.$route.path.includes('canceled')
            )
        },
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        isWebsiteForm() {
            return this.$route.path.includes('interest')
        },
        showVibefamFooter() {
            const excludedStudios = ['mk6yVCAeycWfpBdAQ49V']
            return this.studio && !excludedStudios.includes(this.studio.id)
        },
    },
    methods: {
        ...mapActions({
            authAction: `${AUTHENTICATION_MODULE_NAME}/authAction`,
        }),
    },
}
</script>

<template>
    <div>
        <BackButton />
        <h1>{{ $t('message.myOrders') }}</h1>
        <v-col v-if="loading">
            <v-skeleton-loader
                height="100"
                type="card"
                v-for="i in 5"
                :key="i"
                class="my-3"
            ></v-skeleton-loader>
        </v-col>
        <v-col v-else-if="orders.length == 0">
            <p>You have no orders</p>
        </v-col>
        <v-col v-else>
            <v-card v-for="order in orders" :key="order.id" class="my-3">
                <v-card-title>
                    {{ $t('message.order') }} #{{ order.id }}
                </v-card-title>
                <v-card-subtitle>
                    {{
                        dateTimeStringFromTimestamp(
                            order.createdAt,
                            studio.timezone
                        )
                    }}
                </v-card-subtitle>
                <v-card-text>
                    {{ parsePaidWithText(order) }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <ViewOrderItemsButton :order="order" />
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>
<script>
import ViewOrderItemsButton from '@/components/orders/ViewOrderItemsButton'
import BackButton from '@/components/shared/BackButton.vue'

import { getMyOrders } from '@/util/cloudFunctions/orders'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'

import { dateTimeStringFromTimestamp } from '@/util/dateformat'

export default {
    components: {
        ViewOrderItemsButton,
        BackButton,
    },
    created() {
        this.initData()
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    data() {
        return {
            loading: true,
            orders: [],
        }
    },
    methods: {
        dateTimeStringFromTimestamp,
        async initData() {
            this.loading = true
            const result = await getMyOrders(this.studio.id)
            if (!result.success) {
                this.loading = false
                return
            }
            this.orders = result.docs
            this.loading = false
        },
        parsePaidWithText(order) {
            if (
                order.payment.paymentMethod === 'manual' ||
                order.payment.paymentMethod === 'Manual Payment'
            ) {
                return this.$t('message.paidAmountInStudio', {
                    amount: order.payment.amount,
                    currency: this.studio.currency,
                })
            }
            return this.$t('message.paidAmountWithMethod', {
                amount: order.payment.amount,
                currency: this.studio.currency,
                paymentMethod: order.payment.paymentMethod,
            })
        },
    },
}
</script>

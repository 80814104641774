import { sendContainerRequest } from '@/container'

/**
 * @param {Object} data Has properties {studioID, code}
 * @param {String} userID userID
 * @returns {Object} Success property is set to true if there is at least
 * one eligible package.
 */
export async function getEligiblePackageDiscounts(data) {
    const cloudfn = await sendContainerRequest(
        'getEligiblePackageDiscounts',
        data
    )
    return cloudfn
}

export function extractHostnameAndPort(url) {
    const urlObj = new URL(url)
    const hostname = urlObj.hostname
    const port = urlObj.port

    return {
        hostname: hostname,
        port: port,
    }
}

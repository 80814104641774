import { gettersStudio } from '@/store/moduleComponents/studio/gettersStudio'
import { actionsStudio } from '@/store/moduleComponents/studio/actionsStudio'
import { mutationsStudio } from '@/store/moduleComponents/studio/mutationsStudio'

export const studioModule = {
    namespaced: true,
    state: {
        studio: null,
        loading: true,
        brandedAppsInReview: ['UtXHSQSpOR3337RvSpcY', 'PrLOxbHnjC61r1h5cd11'],
    },
    getters: gettersStudio,
    actions: actionsStudio,
    mutations: mutationsStudio,
}

<template>
    <div>
        <v-card
            class="pb-6 pt-6 mb-1 px-3 mb-2"
            align-self-center="true"
            :to="isClickable ? classLink : ''"
            flat
            width="100vw"
            min-height="15vh"
            v-if="isMobileView"
        >
            <v-row no-gutters>
                <!-- Left side of the card -->
                <v-col cols="7">
                    <v-card-text class="py-2">
                        {{ subItem.timeStart }} - {{ subItem.timeEnd }}
                    </v-card-text>
                    <v-card-text class="py-2 primary-text">
                        {{ subItem.name }}<br />
                        <span class="text-caption" v-if="subItem.spotNumber"
                            >{{
                                $t('message.spotNumber', {
                                    spotNumber: subItem.spotNumber,
                                })
                            }}<br
                        /></span>
                        <span class="appFontColor--text">{{
                            $t('message.displayCredits', {
                                numCredits: numCredits,
                            })
                        }}</span>
                    </v-card-text>
                    <v-card-text class="py-2">
                        <v-icon class="mr-1">mdi-account-circle</v-icon>
                        {{ instructorNames }}
                    </v-card-text>
                    <v-card-text class="py-2">
                        <v-icon class="mr-1">mdi-map-marker</v-icon>
                        {{ subItem.location }}
                    </v-card-text>
                </v-col>
                <!-- Left side of the card -->

                <!-- Right side of the card -->
                <v-col cols="5">
                    <v-card-text
                        class="py-2 d-flex"
                        v-if="
                            subItem.isBookedFromSmartFill &&
                            subItem.smartFillPrice != undefined
                        "
                    >
                        <v-icon class="pr-2">mdi-tag</v-icon>
                        {{
                            $t('message.purchasedAtPrice', {
                                price: subItem.smartFillPrice.toFixed(2),
                            })
                        }}
                    </v-card-text>

                    <div v-if="bookBtn">
                        <v-btn
                            v-if="checkIfBooked"
                            outlined
                            link
                            color="primary"
                            :to="classLink"
                            class="text-capitalize d-flex"
                        >
                            {{ $t('message.bookedClass') }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="isFull"
                            outlined
                            text
                            class="secondary text-capitalize d-flex"
                            link
                            :to="classLink"
                            v-on:click.prevent
                        >
                            {{ $t('message.classesAddToWaitlist') }}
                        </v-btn>
                        <v-btn
                            v-else
                            outlined
                            text
                            class="primary text-capitalize white--text d-flex"
                            link
                            :to="classLink"
                        >
                            {{ $t('message.bookClass') }}
                        </v-btn>
                        <v-card-text
                            class="card-font text--secondary font-italic"
                        >
                            <span v-if="!hideSpots">
                                {{
                                    subItem.capacity - subItem.participantCount
                                }}/{{ subItem.capacity }}
                                {{ $t('message.available') }}
                            </span>
                        </v-card-text>
                    </div>
                    <v-flex v-if="cancelWaitlistBtn" align-self-center>
                        <RemoveClass :subItem="subItem" @success="removed" />
                        <v-card-text
                            class="card-font ml-3 text--secondary font-italic"
                        >
                            {{ subItem.waitlistPosition }}
                            {{ $t('message.position') }}
                        </v-card-text>
                    </v-flex>

                    <div
                        v-if="cancelUpcomingBtn && !subItem.courseName"
                        class="d-flex flex-column align-center"
                    >
                        <CancelBooking
                            :subItems="[subItem]"
                            @success="cancelled"
                            @cancelFeePaymentError="
                                (err) => $emit('cancelFeePaymentError', err)
                            "
                        />
                        <div
                            class="text-caption mt-3"
                            v-if="subItem.isFamilyBookable"
                        >
                            <AssignFamilyUser
                                :classSignup="subItem"
                                @success="assignFamilyUser"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <v-card
            class="card-flex mb-2 pr-2"
            align-self-center="true"
            flat
            width="100vw"
            height="15vh"
            :hover="isClickable"
            :to="isClickable ? classLink : ``"
            v-else
        >
            <v-row>
                <v-col align-self="center">
                    <v-card-text>
                        {{ subItem.timeStart }} - {{ subItem.timeEnd }}
                    </v-card-text>
                </v-col>
                <v-col align-self="center">
                    <v-card-text class="primary--text">
                        {{ subItem.name }}<br />
                        <span class="text-caption" v-if="subItem.spotNumber"
                            >{{
                                $t('message.spotNumber', {
                                    spotNumber: subItem.spotNumber,
                                })
                            }}<br
                        /></span>
                        <span class="appFontColor--text">{{
                            $t('message.displayCredits', {
                                numCredits: numCredits,
                            })
                        }}</span>
                    </v-card-text>
                </v-col>
                <v-col align-self="center">
                    <v-card-text>
                        {{ instructorNames }}
                    </v-card-text>
                </v-col>
                <v-col align-self="center">
                    <v-card-text class="py-2">{{
                        subItem.location
                    }}</v-card-text>
                </v-col>
                <v-col
                    align-self="center"
                    v-if="
                        bookBtn ||
                        cancelWaitlistBtn ||
                        cancelUpcomingBtn ||
                        (subItem.isBookedFromSmartFill &&
                            subItem.smartFillPrice != undefined)
                    "
                >
                    <div v-if="bookBtn">
                        <v-btn
                            v-if="checkIfBooked"
                            outlined
                            link
                            color="primary"
                            :to="classLink"
                            class="text-capitalize card-center"
                        >
                            {{ $t('message.bookedClass') }}
                            <v-icon right>mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="isFull"
                            outlined
                            text
                            class="secondary text-capitalize card-center"
                            link
                            :to="classLink"
                            v-on:click.prevent
                        >
                            {{ $t('message.classesAddToWaitlist') }}
                        </v-btn>
                        <v-btn
                            v-else
                            outlined
                            text
                            class="primary text-capitalize white--text card-center"
                            link
                            :to="classLink"
                        >
                            {{ $t('message.bookClass') }}
                        </v-btn>
                        <v-card-text
                            class="card-font text--secondary font-italic"
                        >
                            <span v-if="!hideSpots">
                                {{
                                    subItem.capacity - subItem.participantCount
                                }}/{{ subItem.capacity }}
                                {{ $t('message.available') }}
                            </span>
                        </v-card-text>
                    </div>
                    <div v-if="cancelWaitlistBtn">
                        <RemoveClass :subItem="subItem" @success="removed" />
                        <v-card-text
                            class="card-font ml-3 text--secondary font-italic"
                        >
                            {{ subItem.waitlistPosition }}
                            {{ $t('message.position') }}
                        </v-card-text>
                    </div>
                    <div
                        style="margin-bottom: 4px"
                        v-if="
                            subItem.isBookedFromSmartFill &&
                            subItem.smartFillPrice != undefined
                        "
                    >
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="green--text"
                                >
                                    {{ $t('message.dashDeal') }} !
                                </div>
                            </template>
                            <div>
                                {{
                                    $t('message.purchasedAtPrice', {
                                        price: subItem.smartFillPrice.toFixed(
                                            2
                                        ),
                                    })
                                }}
                            </div>
                        </v-tooltip>
                    </div>
                    <div
                        v-if="cancelUpcomingBtn && !subItem.courseName"
                        class="text-center"
                    >
                        <CancelBooking
                            :subItems="[subItem]"
                            @success="cancelled"
                            @cancelFeePaymentError="
                                (err) => $emit('cancelFeePaymentError', err)
                            "
                        />
                        <div
                            class="text-caption mt-3"
                            v-if="subItem.isFamilyBookable"
                        >
                            <AssignFamilyUser
                                :classSignup="subItem"
                                @success="assignFamilyUser"
                            />
                        </div>
                    </div>
                </v-col>
                <!-- Dummy column for aesthetics purposes -->
                <v-col v-else></v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import AssignFamilyUser from '@/components/familyusers/AssignFamilyUser.vue'
import CancelBooking from '@/components/myclasses/CancelBooking.vue'
import RemoveClass from '@/components/myclasses/RemoveClass.vue'
import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'

export default {
    props: {
        subItem: Object,
        isClickable: {
            type: Boolean,
            default: false,
        },
        bookBtn: {
            type: Boolean,
            default: true,
        },
        cancelWaitlistBtn: {
            type: Boolean,
            default: false,
        },
        cancelUpcomingBtn: {
            type: Boolean,
            default: false,
        },
        isMobileView: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RemoveClass,
        CancelBooking,
        AssignFamilyUser,
    },
    computed: {
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
        hideSpots() {
            return this.subItem.hideSpots === true
        },
        classLink() {
            const hash = this.$route.hash
            return this.subItem.courseOfferingId
                ? `courses/${this.subItem.courseOfferingId}${hash}`
                : `classes/${this.subItem.classId || this.subItem.id}${hash}`
        },
        numCredits() {
            if (this.subItem.numCredits) {
                return this.subItem.numCredits
            }
            if (this.subItem.numCreditsBooked) {
                return this.subItem.numCreditsBooked
            }
            return 1
        },
        checkIfBooked() {
            if (!this.user) {
                return false
            }
            return this.subItem.spots.some(
                (spot) => spot.username === this.user.username
            )
        },
        isFull() {
            return (
                this.subItem.isFull ||
                this.subItem.participantCount === this.subItem.capacity
            )
        },
        instructorNames() {
            return this.subItem.instructors.map((it) => it.name).join(', ')
        },
    },
    methods: {
        cancelled() {
            this.$emit('cancelled')
        },
        assignFamilyUser() {
            this.$emit('assignFamilyUser')
        },
        removed() {
            this.$emit('removed')
        },
    },
}
</script>

<style scoped>
.card-center {
    margin-bottom: -10px !important;
    margin-top: 40px;
    margin-left: 5px;
}

.card-font {
    font-size: 13px;
}

.card-font-cancel {
    font-size: 13px;
    margin-top: 10px;
}

.card-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
</style>

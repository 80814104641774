var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"height":"100%"}},[_c('div',{style:({
            width: '100%',
            aspectRatio: '12/9',
        })},[_c('v-carousel',{attrs:{"height":"100%"}},_vm._l((_vm.product.images),function(image,i){return _c('v-carousel-item',{key:i},[_c('div',{style:({
                        width: '100%',
                        textAlign: 'center',
                        height: '100%',
                    })},[_c('img',{style:({
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                        }),attrs:{"src":image}})])])}),1)],1),_c('v-card-title',[_c('div',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.product.name))])]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.product.category.name)+" ")]),_c('v-card-text',{staticClass:"ordinary--text"},[_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.product.description))]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('strong',{staticClass:"price"},[_vm._v(_vm._s(`${_vm.studio.currency}${_vm.product.price}`))])]),_c('div',{staticClass:"d-flex flex-column justify-space-between right-col"},[_c('v-btn',{ref:"add-to-cart-btn",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.addToCart}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cart")]),_c('span',{staticClass:"mdi mdi-plus"},[_vm._v(_vm._s(_vm.$t('message.addCart')))])],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
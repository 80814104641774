import { db, doc, getDoc } from '@/firebase'
import { hasRegisteredForCourseOffering } from '../cloudFunctions/coursesFunctions'

export async function getCourseByCourseId(courseId) {
    const docRef = doc(db, 'course_offerings', courseId)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) return { success: false }
    const docData = { ...docSnap.data(), id: courseId }
    const hasRegisteredResult = await hasRegisteredForCourseOffering(courseId)
    const hasRegistered =
        hasRegisteredResult.success && hasRegisteredResult.hasRegistered
    const isBookable = !hasRegistered && !docData.isFull
    const reason = hasRegistered
        ? 'You have signed up for this course already'
        : docData.isFull
        ? 'This course is full'
        : ''
    return { doc: docData, success: true, isBookable, reason }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({
        background:
            _vm.isWidget && _vm.theme === 'light'
                ? '#fff'
                : _vm.$vuetify.theme.themes[_vm.theme].background,
    })},[_c('CollectUserDetails'),_c('router-view'),_c('v-spacer'),(
            !_vm.isBrandedApp &&
            !_vm.isSuccessOrFailedRoute &&
            !_vm.isWebsiteForm &&
            _vm.showVibefamFooter
        )?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { sendContainerRequest } from '@/container'
import { functions, httpsCallable } from '@/firebase'

export function getClassesFromStudioWithoutFilters(
    studioId,
    startDate,
    endDate
) {
    return getClassesFromStudioId({
        studioId,
        startDate,
        endDate,
    })
}

export function getClassesFromStudioId(data) {
    const {
        studioId,
        classFilter,
        instructorFilter,
        locationFilter,
        startDate,
        endDate,
    } = data
    const getClassesByStudio = httpsCallable(functions, 'getClassesByStudio')
    const callData = {
        studioId,
        classFilter,
        instructorFilter,
        locationFilter,
        startDate,
        endDate,
    }
    return getClassesByStudio(callData).then((result) => result.data)
}

export function getUserUpcomingClasses(studioId) {
    const data = { studioId }
    return sendContainerRequest('getMyUpcomingClasses', data).then(
        (result) => result.data
    )
}

export function getUserPastClasses(studioId, lastDocId) {
    const userData = { studioId, lastDocId }
    return sendContainerRequest('getMyPastClasses', userData).then(
        (result) => result.data
    )
}

export function getUserWaitlist(studioId) {
    const data = { studioId }
    return sendContainerRequest('getMyWaitlist', data).then(
        (result) => result.data
    )
}

export function cancelClass(data) {
    const { signupId, canRefund } = data
    const cancelClassSignup = httpsCallable(functions, 'cancelClassSignup')
    return cancelClassSignup({ signupId, canRefund }).then(
        (result) => result.data
    )
}

export function getLateCancellationStatus(signupId) {
    const payload = { signupId }
    return sendContainerRequest('getLateCancellationStatus', payload).then(
        (result) => result.data
    )
}

export function removeWaitlistClass(data) {
    const { waitlistId } = data
    const removeFromWaitlist = httpsCallable(functions, 'removeFromWaitlist')
    return removeFromWaitlist({ waitlistId }).then((result) => result.data)
}

export function getClassByClassId(data) {
    const { classId } = data
    const getClassById = httpsCallable(functions, 'getClassById')
    return getClassById({ classId }).then((result) => result.data)
}

/**
 * Books Class for the logged in user.
 * @param {String} classId
 * @param {Integer[]} spotPositions
 * @param {Integer} numSpots
 * @param {String} packageId
 */
export function bookClass(classId, spotPositions, numSpots, packageId) {
    const bookClassFromWebApp = httpsCallable(functions, 'bookClass')
    return bookClassFromWebApp({ classId, spotPositions, numSpots, packageId })
        .then((result) => result.data)
        .catch(() => ({ success: true }))
}

export function waitlistClass(classId, packageId) {
    const addToWaitlist = httpsCallable(functions, 'addToWaitlist')
    return addToWaitlist({ classId, packageId })
        .then((result) => result.data)
        .catch(() => ({ success: true }))
}
